<template>
  <b-card no-body class="rounded-0">
    <b-overlay id="overlay-background" :show="show" variant="transparent" opacity="0.85" blur="2px" rounded="sm">
      <div class="d-flex justify-content-between">
        <div class="d-flex m-0 my-1 mx-1">
          <feather-icon icon="LockIcon" size="18" />
          <h4 class="align-middle ml-50">Yetkiler ( {{ item.role_adi }} )</h4>
        </div>
        <b-button-group size="sm" class="mr-1">
          <b-button
            squared
            class="btn-icon"
            v-b-tooltip.hover.v-success
            title="Tümünü İşaretle"
            variant="flat-success"
            @click="handlerChecked(true)"
          >
            <feather-icon icon="CheckSquareIcon" />
          </b-button>
          <b-button
            squared
            class="btn-icon"
            v-b-tooltip.hover.v-danger
            title="Tümünü Kaldır"
            variant="flat-danger"
            @click="handlerChecked(false)"
          >
            <feather-icon icon="CheckSquareIcon" />
          </b-button>
        </b-button-group>
      </div>
      <b-table striped hover responsive class="mb-0" :fields="fields" :items="form.authority">
        <template #cell(title)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" @change="handlerCheck(data)" />
        </template>
      </b-table>
      <div class="d-flex border-top justify-content-end p-1">
        <b-button squared class="btn btn-success" @click="handlerSubmit">
          <feather-icon icon="CheckSquareIcon" />
          {{ item.authority != null ? 'Güncelle' : 'Kaydet' }}
        </b-button>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
// import adminRole from '../../../../../api/helpers/admin/adminRole';
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show: false,
    fields: ['title', 'listeleme', 'ekleme', 'duzenleme', 'silme'],
    authority: [],
    form: {},
  }),
  created() {
    this.form.authority = this.authority;
  },
  methods: {
    handlerChecked(payload) {
      if (payload == true) {
        Object.keys(this.form.authority).map((key) => {
          this.form.authority[key].listeleme = true;
          this.form.authority[key].ekleme = true;
          this.form.authority[key].duzenleme = true;
          this.form.authority[key].silme = true;
        });
      } else {
        Object.keys(this.form.authority).map((key) => {
          this.form.authority[key].listeleme = false;
          this.form.authority[key].ekleme = false;
          this.form.authority[key].duzenleme = false;
          this.form.authority[key].silme = false;
        });
      }
    },
    handlerCheck(data) {
      const field = data.field;
      const index = data.index;
      this.form.authority[index][field.key] = !this.form.authority[index][field.key];
    },
    handlerSubmit() {
      this.show = true;
      this.$store.dispatch('rolleGuncelle', this.form).then((res) => {
        if (res.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Ekleme`,
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: `Ekleme başarılı.`,
            },
          });
          this.show = false;
        }
      });
    },
    async handlerCanceled() {
      this.form.authority = [];
      Object.keys(this.authority).map((key) => {
        this.form.authority.push(this.authority[key]);
      });
    },
  },
  watch: {
    item(val) {
      this.show = true;
      this.form.id = val._id;
      this.form.role_adi = val.role_adi;
      if (val.authority !== null && val.authority !== undefined) {
        this.form.authority = [];
        Object.keys(val.authority).map((key) => {
          this.form.authority.push(val.authority[key]);
        });
      } else {
        this.form.authority = this.authority;
      }
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
