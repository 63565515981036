<template>
  <b-card class="card-transaction rounded-0" no-body>
    <b-overlay id="overlay-background" :show="show" variant="transparent" opacity="0.85" blur="2px" rounded="sm">
      <b-card-header>
        <b-card-title>Kullanıcı Rolleri asdasd</b-card-title>

        <b-button
          squared
          variant="flat-success"
          class="btn-icon btn-lg"
          @click="rollAdd = !rollAdd"
          v-b-tooltip.hover.v-success
          title="Yeni Rol Tanımla"
        >
          <feather-icon size="18" icon="PlusSquareIcon" />
        </b-button>
      </b-card-header>

      <div v-if="rollAdd">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" class="px-1" autocomplete="off">
            <b-row>
              <b-col cols="12">
                <validation-provider name="Role Adı" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group>
                    <b-input-group>
                      <b-form-input
                        class="rounded-0"
                        id="role_adi"
                        name="role_adi"
                        ref="role_adi"
                        v-model="form.role_adi"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-input-group-append>
                        <b-button squared type="submit" variant="outline-primary">
                          {{ update == false ? 'Kaydet' : 'Güncelle' }}
                        </b-button>
                      </b-input-group-append>

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <b-alert :show="rolItem.length == 0 ? true : false" dismissible class="mb-0 m-1 rounded-0" variant="warning">
        <div class="alert-body">
          <span>Kayıtlı role bulunmamaktadir.</span>
        </div>
      </b-alert>

      <b-card-body>
        <div v-for="item in rolItem" :key="item._id" class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar rounded size="42" variant="primary">
                <feather-icon icon="EyeIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title my-1">{{ item.role_adi }}</h6>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder text-success">
            <b-button-group size="sm">
              <b-button
                squared
                class="btn-icon"
                v-b-tooltip.hover.v-success
                title="Yetkileri Düzenle"
                variant="flat-success"
                @click="$emit('selectItem', item)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                squared
                class="btn-icon"
                v-b-tooltip.hover.v-primary
                title="Rol Adını Duzenle"
                variant="flat-primary"
                @click="handlerUpdate(item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                squared
                class="btn-icon"
                v-b-tooltip.hover.v-danger
                title="Rol Sil"
                variant="flat-danger"
                @click="handlerRemove(item._id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-button-group>
          </div>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
export default {
  props: {
    rolItem: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    show: false,
    rollAdd: false,
    update: false,
    form: {
      firma_k_no: 'asdfcbreetr',
      role_adi: null,
    },
  }),
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        firma_k_no: 'asdfcbreetr',
        role_adi: null,
      };
      this.show = false;
      this.update = false;
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
      this.$refs.role_adi.focus();
    },
    onSubmit() {
      this.show = true;
      if (this.update == false) {
        this.$store.dispatch('rolleEkle', this.form).then((res) => {
          if (res.data.success === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ekleme`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: `Ekleme başarılı.`,
              },
            });
            this.resetForm();
          }
        });
      } else {
        this.$store.dispatch('rolleGuncelle', this.form).then((res) => {
          if (res.data.success === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ekleme`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: `Ekleme başarılı.`,
              },
            });
            this.resetForm();
          }
        });
      }
    },
    handlerUpdate(item) {
      this.update = true;
      this.rollAdd = true;
      this.form = { ...item, id: item._id };
    },
    handlerRemove(id) {
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('rolleSil', id).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Silme başarılı.`,
                },
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
