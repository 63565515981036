<template>
  <b-overlay id="overlay-background" :show="show" variant="transparent" opacity="0.85" blur="2px" rounded="sm">
    <b-row>
      <b-col cols="12" md="4" lg="4">
        <kullanici-roller :rolItem="rolItem" @selectItem="item = $event" />
      </b-col>
      <b-col cols="12" md="8" lg="8">
        <yetkiler :item="item" />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import KullaniciRoller from './component/roller';
import Yetkiler from './component/yetkiler';
export default {
  components: {
    KullaniciRoller,
    Yetkiler,
  },

  data: () => ({
    show: false,
    item: {},
    rolItem: [],
  }),

  async created() {
    await this.handlerRoller();
  },
  methods: {
    async handlerRoller() {
      this.show = true;
      await this.$store.dispatch('rolleListele');
      this.rolItem = await this.$store.getters.getRoller;
      this.show = false;
    },
  },
  watch: {
    item(val) {},
  },
};
</script>

<style lang="scss" scoped></style>
